<template>
	<div class="hypertext">
		<h5>基本信息</h5>
		<div class="table">
			<ul>
				<li>
					<span style="flex:1">编号:</span>
					<span style="flex:2">201505445</span>
					<span style="flex:1">借款人:</span>
					<span style="flex:2">柯先生</span>
				</li>
				<li>
					<span style="flex:1">借款标题:</span>
					<span style="flex:2">
						<el-input v-model="title"></el-input>
					</span>
					<span style="flex:1">借款类型:</span>
					<span style="flex:2">信用借</span>
				</li>
				<li>
					<span style="flex:1">借款金额:</span>
					<span style="flex:2">
						<el-input v-model="money"
							><template slot="append">元</template></el-input
						>
					</span>
					<span style="flex:1">借款期限:</span>
					<span style="flex:2">
						<el-input v-model="timelLimit"
							><template slot="append">个月</template></el-input
						>
					</span>
				</li>
				<li>
					<span style="flex:1">还款方式:</span>
					<span style="flex:2">
						<el-select v-model="value">
							<el-option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</span>
					<span style="flex:1">计息方式:</span>
					<span style="flex:2">
						<el-select v-model="value">
							<el-option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</span>
				</li>
				<li>
					<span style="flex:1">年利率:</span>
					<span style="flex:2">
						<el-input v-model="interest"
							><template slot="append">%</template></el-input
						>
					</span>
					<span style="flex:1">总计利息:</span>
					<span style="flex:2">50.11元</span>
				</li>
				<li>
					<span style="flex:1">有效期:</span>
					<span style="flex:2">
						<el-select v-model="value">
							<el-option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</span>
					<span style="flex:1">预计还款时间:</span>
					<span style="flex:2">
						<el-date-picker v-model="date" type="date" placeholder="选择日期">
						</el-date-picker>
					</span>
				</li>
			</ul>
		</div>
		<h5>投标奖励</h5>
		<div class="reward">
			<span style="flex:1"><div>不予奖励</div></span>
			<span style="flex:3">
				奖励比例
				<input type="text" v-model="interest" />
				%
			</span>
		</div>
		<h5>投标限制</h5>
		<div class="table">
			<ul>
				<li>
					<span style="flex:1"
						>按金额区间限制 最低金额:
						<input type="text" v-model="interest" /> 元 最高金额:
						<input type="text" v-model="interest" /> 元</span
					>
				</li>
				<li>
					<span style="flex:1"
						>按金额区间限制 每份金额:
						<input type="text" v-model="interest" /> 份 最低分数:
						<input type="text" v-model="interest" /> 份 最高分数:
						<input type="text" v-model="interest" /> 份</span
					>
				</li>
			</ul>
		</div>
		<h5>借款图像</h5>
		<el-upload
			action="https://jsonplaceholder.typicode.com/posts/"
			list-type="picture-card"
			:on-preview="handlePictureCardPreview"
			:on-remove="handleRemove"
			:auto-upload="false"
			:on-change="handleSuccess"
      :limit=1
		>
			<i class="el-icon-plus"></i>
		</el-upload>
		<div><input type="text" v-model="imageId" />.png</div>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
		<h5>借款限制</h5>
		<div class="reward">
			<span style="flex:1"
				><div>限制向标 向标密码: <input type="text" v-model="interest" /></div
			></span>
		</div>
		<h5>借款详情</h5>
		<editor />
    <div class="submit">提交</div>
	</div>
</template>

<script>
import editor from './editor.vue'
// import SnowflakeId from "snowflake-id";
export default {
	components: {
		editor,
	},
	data() {
		return {
			title: '我要买房',
			money: '10000',
			timelLimit: '10',
			interest: '100',
			date: '',
			options: [
				{
					value: '选项1',
					label: '黄金糕',
				},
				{
					value: '选项2',
					label: '双皮奶',
				},
				{
					value: '选项3',
					label: '蚵仔煎',
				},
				{
					value: '选项4',
					label: '龙须面',
				},
				{
					value: '选项5',
					label: '北京烤鸭',
				},
			],
			value: '',
			dialogImageUrl: '',
			dialogVisible: false,
			imageId: '',
		}
	},
	methods: {
		handleRemove(file, fileList) {
			console.log(file, fileList)
      this.imageId = ''
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url
			this.dialogVisible = true
		},
		handleSuccess(file) {
			console.log(file.raw.uid)
			this.imageId = file.uid
		},
	},
}
</script>

<style scoped lang="scss">
.hypertext {
	h5 {
		padding: 0 40px;
		margin-top: 20px;
    font-weight: 700;
	}
	.table {
		ul {
			padding: 0;
			margin: 20px;
			border: 1px solid #d7d7d7;
			border-bottom: none;
			border-right: none;
			li {
				display: flex;
				span {
					display: inline-block;
					height: 50px;
					line-height: 50px;
					padding: 0 20px;
					border-right: 1px solid #d7d7d7;
					border-bottom: 1px solid #d7d7d7;
					.el-select {
						width: 100%;
					}
					::v-deep {
						.el-date-editor.el-input,
						.el-date-editor.el-input__inne {
							width: 100%;
						}
					}
				}
			}
		}
	}
	.reward {
		height: 50px;
		border: 1px solid #d7d7d7;
		margin: 20px;
		display: flex;
		line-height: 50px;
		border-right: none;
		span {
			border-right: 1px solid #d7d7d7;
			padding: 0 20px;
		}
	}
	::v-deep {
		.el-upload--picture-card {
			margin: 20px;
		}
		.el-upload-list--picture-card .el-upload-list__item {
			margin: 20px 0 0 20px;
		}
	}
	input {
		height: 40px;
		border: 1px solid #dcdfe6;
		border-radius: 5px;
		margin-left: 10px;
		outline: #e62d31;
	}
	input:focus {
		border-color: #e62d31;
	}
  .submit{
    display: inline-block;
    width: 100px;
    height: 50px;
    background-color: #4285f4;
    margin: 20px;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    margin-top: 0;
    float:right;
  }
  .submit:hover{
    background-color: #d12;
  }
}
</style>
