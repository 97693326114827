<template>
  <div class="editor-wang">
    <div id="wangEditor" ref="editor"></div>
  </div>
</template>
 
<script>
// 引入 wangEditor
import wangEditor from "wangeditor";
export default {
  data() {
    return {
      editor: null,
      editorData: ``,
      data:'',
    };
  },
  props: {
    editorDetail: String,
  },
  mounted() {
    const editor = new wangEditor(`#wangEditor`);
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };
    editor.config.uploadImgServer = "https://www.gkh0305.top:8081/uploads";
    editor.config.uploadFileName = "uploadFiles";
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024; // 5M
    // 创建编辑器
    editor.create();
    this.editor = editor;
    setTimeout(()=>{
      this.editor.txt.html(this.editorDetail);
    },1000)
  },
  methods: {
    submit() {
      // 通过代码获取编辑器内容
      this.data = this.editor.txt.text();
      console.log(this.data);
    },
  },
 
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style scoped lang="scss">
.editor-wang{
  #wangEditor{
    margin: 20px;
  }
}
</style>